const header = {
    en: {
        "header.nav.casino": "Casino",
        "header.nav.casino.live": "Live casino",
        "header.nav.sport": "Sports",
        "header.nav.sport.live": "Sports Live",
        "header.nav.sport.virtual": "Virtuals",
        "header.nav.promotions": "Promotions",
        "header.nav.referral": "Referral",
        "header.nav.vip": "VIP",
        "header.nav.gamification": "WinReto",
        "header.nav.download": "Download the app",
        "header.nav.contact": "Contact us",
        "header.nav.contact.item_1": "Live Chat",
        "header.nav.information": "Information",
        "header.nav.information.item_1": "Open An Account",
        "header.nav.information.item_2": "Deposits To Your Account",
        "header.nav.information.item_3": "Bonus Information",
        "header.nav.information.item_4": "Betting Rules",
        "header.button.signup": "Register",
        "header.button.login": "Login",
        "header.app.title": "Download and Play",
        "header.app.button": "Download",
    },
    es: {
        "header.nav.casino": "Casino",
        "header.nav.casino.live": "Casino En Vivo",
        "header.nav.sport": "Deportes",
        "header.nav.sport.live": "Deportes en vivo",
        "header.nav.sport.virtual": "Virtuales",
        "header.nav.promotions": "Promociones",
        "header.nav.referral": "Referencia",
        "header.nav.vip": "VIP",
        "header.nav.gamification": "WinReto",
        "header.nav.download": "Descargar la aplicación",
        "header.nav.contact": "Contacto",
        "header.nav.contact.item_1": "Chat En Vivo",
        "header.nav.information": "Información",
        "header.nav.information.item_1": "Abrir Una Cuenta",
        "header.nav.information.item_2": "Depositos En Tu Cuenta",
        "header.nav.information.item_3": "Informacion Sobre Bonos",
        "header.nav.information.item_4": "Reglas De Apuestas",
        "header.button.signup": "Regístrate",
        "header.button.login": "Acceder",
        "header.app.title": "Descargar y jugar",
        "header.app.button": "Descargar",
    },
};

export default header;
