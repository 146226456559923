import { getAppLink } from "../utils/getAppLink";
import { getMobileOperatingSystem, is_safari } from "../utils/utils";
import { internalGetCookie } from '../utils/cookies';
import { AffiliateProduct, getValueFromBrowser } from "../utils/trackingQueryHook";

const sidemenuLink = document.querySelector('.sidemenu .download__android');
if (sidemenuLink && (getMobileOperatingSystem() === 'iOS' || getMobileOperatingSystem() === 'MacOS')) {
  sidemenuLink.classList.add('download__ios');
  sidemenuLink.classList.remove('download__android');
  sidemenuLink.innerHTML = sidemenuLink.innerHTML.replace('android', 'ios');
}

const openLink = (element, needLinkForIOSApp = true) => {
  element.addEventListener('click', () => getAppLink(needLinkForIOSApp));
}

const onDownloadClick = () => {
  document.querySelectorAll('.download__android').forEach((appLink) => {
    openLink(appLink, false);
  });
  document.querySelectorAll('.download__ios').forEach((appLink) => {
    openLink(appLink, true);
  });
};

onDownloadClick();

const handleAppPanel = () => {
    const appPanel = document.getElementById('app__panel');
    const linkButton = document.getElementById('app__panel__link');
    const closeButton = document.getElementById('app__panel__close');

    if (is_safari) {
      appPanel.classList.remove('open');
      return;
    }

    if (internalGetCookie('mobile-app') === '1' || !(
      getMobileOperatingSystem().toLowerCase() === 'ios' ||
      getMobileOperatingSystem().toLowerCase() === 'android'
    )) { // check if mobile app or not mobile device
        if (appPanel.classList.contains('open')) {
            appPanel.classList.remove('open');
        }
    } else {
        if (!appPanel.classList.contains('open')) {
            appPanel.classList.add('open');
        }
        openLink(linkButton, getMobileOperatingSystem().toLowerCase() === 'ios');
        closeButton.addEventListener('click', () => {
          if (appPanel.classList.contains('open')) {
            appPanel.classList.remove('open');
          }
        });
    }
};

handleAppPanel();

const metaForApp = document.createElement('meta');
const appId = +getValueFromBrowser('product') === AffiliateProduct.Sport ? 6446956929 : 1632635281;
metaForApp.name="apple-itunes-app";
metaForApp.content = `app-id=${appId}`;
document.getElementsByTagName('head')[0].appendChild(metaForApp);
